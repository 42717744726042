import get from 'lodash.get';

export const startPageTransform = (query) => {
  const queryData = query.nodeQuery.entities[0];

  const data = {
    hero: {
      video: get(queryData, 'fieldBackgroundVideo.entity.fieldVideo.entity.url', null),
      header: get(queryData, 'fieldTextIntro.value', ''),
    },
    logo: queryData.fieldLogoSvg
      ? {
          src: get(queryData, 'fieldLogoSvg.entity.url', null),
          alt: get(queryData, 'fieldLogoSvg.alt', ''),
          title: get(queryData, 'fieldLogoSvg.title', ''),
        }
      : null,
    buttons: get(queryData, 'fieldLinkToSite', []).map((link) => ({
      url: get(link, 'url.path', '/'),
      label: get(link, 'title', ''),
    })),
  };

  return data;
};
