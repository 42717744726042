import React, { useEffect, useState } from 'react';

// === Components === //
import Page from '@components/layout/Page';
import BookmarkList from '@components/sections/BookmarkList';
import HeroVideo from '@components/sections/HeroVideo';
import StartPageModal from '@components/layout/StartPageModal';

// === Content === //
import { patientHomepageData } from '@graphql/PatientHomepage';

// === Helpers === //
import Cookies from 'js-cookie';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoints, respondFrom } from '@styles';

const BookmarsListWrapper = styled.div`
  transform: translateY(-150px);
  position: relative;
  z-index: 3;

  ${respondFrom(
    breakpoints.md,
    css`
      transform: translateY(-80px);
      margin-bottom: -80px;
    `
  )}
`;

const PageWrapper = styled.div`
  display: none;
  overflow-x: hidden;

  &.is-open {
    display: block;
  }
`;

const Homepage = () => {
  const variant = 'blue';
  const pageType = 'patient_page';
  const data = patientHomepageData();

  const [modalOpen, handleModalOpen] = useState<boolean>(false);

  const checkPageType = () => {
    if (Cookies.get('rethinkFabry-view')) {
      handleModalOpen(false);

      if (Cookies.get('rethinkFabry-view') === 'hcp') {
        // redirect to hcp
        if (typeof window !== 'undefined') {
          (window as any).location = '/hcp';
        }
      }
    } else {
      handleModalOpen(true);
    }
  };

  useEffect(() => {
    checkPageType();
  }, []);

  return (
    <>
      <PageWrapper className={!modalOpen ? 'is-open' : ''}>
        <Page variant={variant} pageType={pageType} metaData={data.metaData} pageUrl="/">
          <>
            <HeroVideo data={data.hero} pageType={pageType} />

            <BookmarsListWrapper>
              <BookmarkList data={data.bookmarks} variant={variant} />
            </BookmarsListWrapper>
          </>
        </Page>
      </PageWrapper>
      <StartPageModal variant={variant} modalOpen={modalOpen} handleModalOpen={handleModalOpen} />
    </>
  );
};

export default Homepage;
