import React, { useState } from 'react';

// === Components === //
import Page from '@components/layout/Page';
import HeroVideo from '@components/sections/HeroVideo';

// === Content === //
import { startPageData } from '@graphql/StartPage';
import { patientHomepageData } from '@graphql/PatientHomepage';

// === Styles === //
import styled from '@emotion/styled';
import { colors } from '@styles';

// === Types === //
import { Variant } from '@content/types/layout';

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: ${colors.white};
  z-index: 999;
  min-height: 100vh;
  overflow-y: auto;
  display: none;

  &.is-open {
    display: block;
  }
`;

interface StartPageModalProps {
  variant: Variant;
  modalOpen: boolean;
  handleModalOpen: Function;
}

const StartPageModal = ({ variant, modalOpen, handleModalOpen }: StartPageModalProps) => {
  const pageType = 'start_page';
  const data = startPageData();
  const patientHpData = patientHomepageData();

  return (
    <ModalWrapper className={modalOpen ? 'is-open' : ''}>
      <Page variant={variant} pageType={pageType} metaData={patientHpData.metaData} pageUrl="/">
        <HeroVideo
          data={data.hero}
          buttonsData={data.buttons}
          logo={data.logo || undefined}
          pageType={pageType}
          handleModalOpen={handleModalOpen}
        />
      </Page>
    </ModalWrapper>
  );
};

export default StartPageModal;
