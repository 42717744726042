import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/StartPage';

export const startPageData = () => {
  const data = useStaticQuery(graphql`
    query startPageQuery {
      dGraphQl {
        nodeQuery(filter: { conditions: { field: "type", value: "start_page" } }) {
          entities {
            ... on DrupalGraphQl_NodeStartPage {
              fieldBackgroundVideo {
                entity {
                  ... on DrupalGraphQl_ParagraphBackgroundVideo {
                    fieldVideo {
                      entity {
                        url
                      }
                    }
                  }
                }
              }
              fieldLinkToSite {
                url {
                  path
                }
                title
              }
              fieldLogoSvg {
                alt
                title
                entity {
                  url
                }
              }
              fieldTextIntro {
                value
              }
            }
          }
        }
      }
    }
  `);

  return transformers.startPageTransform(data.dGraphQl);
};
